import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext';
import { ReactComponent as FrFlag } from '../../assets/flags/fr.svg';
import { ReactComponent as GbFlag } from '../../assets/flags/gb.svg';
import { ReactComponent as EsFlag } from '../../assets/flags/es.svg';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isDarkMode, toggleDarkMode, fontSize, setFontSize, language, setLanguage } = useTheme()
  const [showSettings, setShowSettings] = useState(false)

    // Add this state to control the actual DOM presence
    const [isRendered, setIsRendered] = useState(false);
  
    // Handle the animation sequence
    useEffect(() => {
      let animationTimeout: any;
      
      if (showSettings) {
        setIsRendered(true); // Immediately add to DOM when showing
      } else {
        // Set a timeout to remove from DOM after transition completes
        animationTimeout = setTimeout(() => {
          setIsRendered(false);
        }, 300); // Match this to your transition duration
      }
      
      // Cleanup on unmount or before next effect run
      return () => {
        if (animationTimeout) clearTimeout(animationTimeout);
      };
    }, [showSettings]);
  

  return (
    <div className="flex flex-col h-screen px-4 py-8 md:px-8 md:mx-0 md:flex-row-reverse cursor-default dark:bg-black dark:text-white min-h-screen transition-colors duration-500">
      <header className="flex flex-col md:mr-6 pb-4 md:pb-0 items-end md:h-1/3 md:fixed">
        <div className='flex flex-col items-end text-xl'>
          <p>Joachim Dorel</p>
          {/* <p className="text-gray-600 dark:text-gray-400 mb-6">Software Engineer</p> */}
        </div>
        
        <nav className="flex flex-col md:flex-col items-end my-2">
          {[
            { path: '/', label: 'home' },
            { path: '/about', label: 'about' },
            { path: '/projects', label: 'projects' }
          ].map((link) => (
            <NavLink
              key={link.path}
              to={link.path}
              className={({ isActive }) => 
                `text-blue-700 dark:text-white hover:text-white hover:bg-blue-700 hover:bg-blue-700 hover:dark:text-white  ${isActive ? 'bg-blue-700 text-white' : ''}`
              }
            >
              {link.label}
            </NavLink>
          ))}
        </nav>
        

        <div className='flex flex-col'>
          <button
            onClick={() => setShowSettings(!showSettings)}
            className={`hover:bg-gray-300 hover:text-white hover:dark:text-black ${showSettings ? 'bg-gray-300 text-white dark:text-black': 'text-gray-300'}`}
            aria-label="Show settings"
          >
            settings
          </button>
          <div 
            className={`
              overflow-hidden transition-all duration-500 ease-in-out
              ${showSettings 
                ? 'max-h-32 opacity-100' 
                : 'max-h-0 opacity-0'
              }
            `}
          >
            {isRendered && (
              <div className='flex flex-col items-end transition-all duration-500'>        
                <div className='flex flex-row-reverse my-1'>
                  <button 
                    onClick={toggleDarkMode}
                    className=""
                    aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
                  >
                    {isDarkMode ? (
                      <p className='hover:bg-white w-4 h-4 rounded-full border-2 hover:border-black hover:text-black transition-colors'>&nbsp;</p>
                    ) : (
                      <p className='hover:bg-black w-4 h-4 rounded-full border-2 hover:border-white border-gray-300 dark:border-white hover:border-black hover:text-white transition-colors'>&nbsp;</p>
                    )}
                  </button>
                </div>
                <div className='flex flex-row text-sm text-gray-300 dark:text-gray-400'>
                  <button
                    onClick={() => setFontSize('sm')}
                    className={`hover:bg-gray-300 w-4 hover:text-white ${fontSize === 'sm' ? 'bg-gray-300 text-white dark:text-black' : ''}`}
                    aria-label="Set font size to small"
                  >
                    <p className=''>1</p>
                  </button>
                  <button
                    onClick={() => setFontSize('md')}
                    className={`hover:bg-gray-300 w-4 hover:text-white ${fontSize === 'md' ? 'bg-gray-300 text-white dark:text-black' : ''}`}
                    aria-label="Set font size to medium"
                  >
                    <p className=''>2</p>
                  </button>
                  <button
                    onClick={() => setFontSize('xl')}
                    className={`hover:bg-gray-300 w-4 hover:text-white ${fontSize === 'xl' ? 'bg-gray-300 text-white dark:text-black' : ''}`}
                    aria-label="Set font size to extra large"
                  >
                    <p className=''>3</p>
                  </button>
                </div>
                <div className='flex flex-row'>
                  <button
                    onClick={() => setLanguage('en')}
                    className={`hover:opacity-80 w-4 h-3 overflow-hidden ${language === 'en' ? '' : 'opacity-60'}`}
                    aria-label="Set language to English"
                  >
                    <GbFlag className="w-full h-full object-cover" />
                  </button>
                  <button
                    onClick={() => setLanguage('fr')}
                    className={`hover:opacity-80 w-4 h-3 overflow-hidden ${language === 'fr' ? '' : 'opacity-60'}`}
                    aria-label="Set language to French"
                  >
                    <FrFlag className="w-full h-full object-cover" />
                  </button>
                  <button
                    onClick={() => setLanguage('es')}
                    className={`hover:opacity-80 w-4 h-3 overflow-hidden ${language === 'es' ? '' : 'opacity-60'}`}
                    aria-label="Set language to Spanish"
                  >
                    <EsFlag className="w-full h-full object-cover" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

      </header>

      <main className="flex md:mx-auto md:w-1/2 h-2/3 md:h-full text-start">
        {children}
      </main>
    </div>
  );
};

export default Layout;