import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation(); 

  return (
    <div className="flex flex-col h-full justify-center items-start gap-4">
    <section className="text-left">
      <p>{t('homePage.helloWorld')}</p>
      <p className="mt-4">{t('homePage.shortPresentation')}</p>
      <p className="mt-4">{t('homePage.otherHobbies')}</p>
    </section>

    <section className="">
      <h2 className="underline">connect</h2>
      <div className="flex flex-row gap-1 mt-2">
        <div>
          <a
            className="hover:text-white dark:hover:text-white hover:bg-gray-500 dark:text-gray-500"
            href="https://github.com/joachimdorel"
            target="_blank"
            rel="noreferrer"
          >
            github
          </a>
        </div>
        <div>
          <a
            className="hover:text-white dark:hover:text-white hover:bg-blue-700 text-blue-700 dark:text-blue-400 dark:hover:bg-blue-600"
            href="mailto:steep_ticking.0y@icloud.com"
            target="_blank"
            rel="noreferrer"
          >
            email
          </a>
        </div>
        <div>
          <a
            className="hover:text-white dark:hover:text-white hover:bg-orange-700 text-orange-700 dark:text-orange-400 dark:hover:bg-orange-600"
            href="https://www.strava.com/athletes/11020400"
            target="_blank"
            rel="noreferrer"
          >
            strava
          </a>
        </div>
      </div>
    </section>
  </div>

  );
};

export default HomePage;