import { useTranslation } from 'react-i18next';
import logoBottomline from '../assets/logosCompanies/bottomline.png';
import logoFNE from '../assets/logosCompanies/fne.png';
import logoIgocreate from '../assets/logosCompanies/igocreate.png';
import logoKamoov from '../assets/logosCompanies/kamoov.png';

const AboutPage = () => {
  const { t } = useTranslation(); 

  return (
    <div className="">
      <section className="flex flex-col gap-1">
        <p className="">{t('presentationPage.presentation.shortPresentation1')}</p>
        <p className="">{t('presentationPage.presentation.shortPresentation2')}</p>
        <p className="my-4">
          <a 
            href="/files/JoachimDorel_CV.pdf" 
            target="_blank" 
            className='text-blue-700 dark:text-blue-400 hover:text-white hover:bg-blue-700 dark:hover:bg-blue-600 cursor-pointer'
          >
            {t('presentationPage.presentation.cvDownload')} →
          </a>
        </p>
      </section>

      <section className="pb-8">
        <h2 className="text-black-800 dark:text-white font-bold">{t('homePage.experience.title')}</h2>        
        <div className="mt-4 space-y-6">
          <div>
            <div className='flex w-full justify-between'>
              <h3 className="font-medium">Qiara</h3>
              <h3 className="font-medium">2024 - currently</h3>
            </div>
            <p className="text-gray-600 dark:text-gray-400">{t('homePage.experience.igocreateTitle')}</p>
            <ul className="mt-2 list-disc pl-5 space-y-1 ">
              {(t('presentationPage.experience.igocreate.contributions', { returnObjects: true }) as string[]).map((contribution: string, index: number) => (
                <li key={index}>{contribution}</li>
              ))}
            </ul>
          </div>
          <div>
            <div className='flex w-full justify-between'>
              <h3 className="font-medium">Igocreate</h3>
              <h3 className="font-medium">2020 - 2022</h3>
            </div>
            <p className="text-gray-600 dark:text-gray-400">{t('homePage.experience.igocreateTitle')}</p>
            <ul className="mt-2 list-disc pl-5 space-y-1">
              {(t('presentationPage.experience.igocreate.contributions', { returnObjects: true }) as string[]).map((contribution: string, index: number) => (
                <li key={index}>{contribution}</li>
              ))}
            </ul>
          </div>
          
          <div>
            <div className='flex w-full justify-between'>
              <h3 className="font-medium">Bottomline Technologies</h3>
              <h3 className="font-medium">2017</h3>
            </div>
            <p className="text-gray-600 dark:text-gray-400">{t('homePage.experience.bottomlineTitle')}</p>
            <ul className="mt-2 list-disc pl-5 space-y-1">
              {(t('presentationPage.experience.bottomline.contributions', { returnObjects: true }) as string[]).map((contribution: string, index: number) => (
                <li key={index}>{contribution}</li>
              ))}
            </ul>
          </div>
          
          <div>
            <div className='flex w-full justify-between'>
              <h3 className="font-medium">Kamoov</h3>
              <h3 className="font-medium">2016</h3>
            </div>            
            <p className="text-gray-600 dark:text-gray-400">{t('homePage.experience.kamoovTitle')}</p>
            <ul className="mt-2 list-disc pl-5 space-y-1">
              {(t('presentationPage.experience.kamoov.contributions', { returnObjects: true }) as string[]).map((contribution: string, index: number) => (
                <li key={index}>{contribution}</li>
              ))}
            </ul>
          </div>
          
          <div className=''>
            <div className='flex w-full justify-between'>
                <h3 className="font-medium">FNE</h3>
                <h3 className="font-medium">2015</h3>
            </div>
            <p className="text-gray-600 dark:text-gray-400">{t('homePage.experience.fneTitle')}</p>
            <ul className="mt-2 list-disc pl-5 space-y-1">
              {(t('presentationPage.experience.fne.contributions', { returnObjects: true }) as string[]).map((contribution: string, index: number) => (
                <li key={index}>{contribution}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;