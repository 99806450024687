import React from 'react';
import { Link } from 'react-router-dom';

const ProjectsPage = () => {
  return (
    <div className="mt-8">
      <section className=" ">
        <h1 className="text-2xl font-bold mb-4">Projects</h1>
        <p>Some of my personal projects and experiments.</p>
      </section>

      <section className=" mt-8">
        <div className="flex flex-col gap-8">
          <div>
            <h2 className="font-medium">
              <Link to="https://github.com/joachimdorel/XCStrings-Deepl-Translator" className="text-blue-700 dark:text-blue-400 hover:text-white dark:hover:text-white hover:bg-blue-700">XCStrings Deepl Translator</Link>
            </h2>
            <p className="">
            A Python utility that uses the DeepL API to automatically translate iOS Localizable.xcstrings files into multiple languages, streamlining the localization process for iOS apps.
            </p>
          </div>

          <div>
            <h2 className="font-medium">
              <Link to="/mondrian" className="text-blue-700 dark:text-blue-400 hover:text-white dark:hover:text-white hover:bg-blue-700">Mondrian</Link>
            </h2>
            <p className="">
              An interactive visualization inspired by Piet Mondrian's abstract art style. Built with React and CSS Grid.
            </p>
          </div>

          <div>
            <h2 className="font-medium">
              <Link to="/fermat" className="text-blue-700 dark:text-blue-400 hover:text-white dark:hover:text-white hover:bg-blue-700">Fermat Game</Link>
            </h2>
            <p className="">
              A mathematical puzzle game. Test your number skills and pattern recognition.
            </p>
          </div>

          <div>
            <h2 className="font-medium">
              <a href="https://github.com/joachimdorel" target="_blank" rel="noreferrer" className="text-blue-700 dark:text-blue-400 hover:text-white dark:hover:text-white hover:bg-blue-700">
                More on GitHub →
              </a>
            </h2>
            <p className="">
              Check out my other projects and contributions on GitHub.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectsPage;