import React, { useEffect } from 'react';
import './App.css';
// import "@fontsource/ibm-plex-sans";

import { BrowserRouter as Router, Route, Routes, RouterProvider, createBrowserRouter, useLocation, Outlet } from 'react-router-dom';

import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ProjectsPage from './pages/Projects';
import Mondrian from './components/mondrian/Mondrian';
import Fermat from './components/fermat/fermat';
import Layout from './components/Layout';
import loadClickyScript from './tools/clicky/useClicky';
import { ThemeProvider } from './context/ThemeContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/projects",
        element: <ProjectsPage />,
      },
      {
        path: "/mondrian",
        element: <Mondrian />,
      },
      {
        path: "/fermat",
        element: <Fermat />,
      }
    ]
  }
]);

function RootLayout() {
  return (
    <>
      <RouteChangeTracker />
      <Layout>
        <Outlet />
      </Layout>
    </>
  )
}

const App: React.FC = () => {
  useEffect(() => {
    loadClickyScript('101436159')
  });

  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

const RouteChangeTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.clicky) {
      window.clicky.log(location.pathname, document.title);
    }
  }, [location]);

  return null;
}; 

export default App;
