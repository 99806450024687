import React, { createContext, useContext, useEffect, useState } from 'react';

type FontSize = 'sm' | 'md' | 'xl'

type Languages = 'fr' | 'en' | 'es'

type ThemeContextType = {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
  fontSize: FontSize;
  setFontSize: (size: FontSize) => void;
  language: Languages;
  setLanguage: (lang: Languages) => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Check if user prefers dark mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check local storage first
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') return true;
    if (savedTheme === 'light') return false;
    
    // If no saved preference, check system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  const [fontSize, setFontSizeState] = useState<FontSize>(() => {
    const savedFontSize = localStorage.getItem('fontSize') as FontSize;
    return savedFontSize && ['sm', 'md', 'xl'].includes(savedFontSize) 
      ? savedFontSize 
      : 'md';
  });
  
  const [language, setLanguageState] = useState<Languages>(() => {
    const savedLanguage = localStorage.getItem('language') as Languages;
    return savedLanguage && ['en', 'fr', 'es'].includes(savedLanguage) 
      ? savedLanguage 
      : 'en';
  });

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
    
    document.documentElement.classList.remove('text-sm', 'text-md', 'text-xl');
    document.documentElement.classList.add(`text-${fontSize}`);
  }, [fontSize]);

  useEffect(() => {
    localStorage.setItem('language', language);
    
    // Update the i18next language
    import('../i18next').then(i18nModule => {
      const i18n = i18nModule.default;
      i18n.changeLanguage(language);
    });
  }, [language]);

  // Listen for system preference changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e: MediaQueryListEvent) => {
      // Only update if user hasn't manually set a preference
      if (!localStorage.getItem('theme')) {
        setIsDarkMode(e.matches);
      }
    };
    
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  const setFontSize = (size: FontSize) => {
    setFontSizeState(size);
  };
  
  const setLanguage = (lang: Languages) => {
    setLanguageState(lang);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode, fontSize, setFontSize, language, setLanguage }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};